.portfolio-slider-outer .slider-image {
    height: 70vh;
    width:100%;
    object-fit: cover;
}

.portfolio .slider-image-div .image-title:hover {
    scale: 1.2;
    transition: .5s ;
    transform: translateX(-20px);
}

.portfolio .slider-image-div .image-title{
    position: absolute;
    bottom: 1vh;
    right: 2vw;
    color: white;
    text-shadow: -1px 1px black;
    font-family: Louis George Cafe;
    font-size: 1.5rem;
    cursor: pointer;
    transition: .5s ;
}

.product-title-div {
    position:absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 25, 39, 0.2);
}

.product-title {
    text-align: center;
    color: white;
    font-family: Louis George Cafe;
    font-size: 4vw;
    margin: 0 10vw;
}

.product-title a {
    text-decoration: none;
    color: white;
}

.alice-carousel .animated {
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
  
  .alice-carousel .animated-out {
    z-index: 1;
  }
  
  .alice-carousel .fadeOut {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
  }
  
  @-webkit-keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      visibility: hidden;
    }
  }
  
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      visibility: hidden;
    }
  }
  .alice-carousel {
    position: relative;
    width: 100%;
    margin: auto;
    direction: ltr;
  }
  
  .alice-carousel__wrapper {
    position: relative;
    overflow-x: hidden;
    overflow-y: hidden;
    box-sizing: border-box;
    width: 100%;
    height: auto;
  }
  
  .alice-carousel__stage {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    white-space: nowrap;
    transform-style: flat;
    -webkit-transform-style: flat;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
  }
  .alice-carousel__stage-item {
    position: relative;
    display: inline-block;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    vertical-align: top;
    white-space: normal;
    line-height: 0;
    object-fit: contain;
  }
  .alice-carousel__stage-item * {
    line-height: initial;
  }
  .alice-carousel__stage-item.__hidden {
    opacity: 0;
    overflow: hidden;
  }

  .portfolio .alice-carousel__prev-btn,
  .portfolio .alice-carousel__next-btn {
    display: inline-block;
    box-sizing: border-box;
    width: 50%;
    padding: 10px 5px;
    transform: translateY(-44.8vh);
  }

  .alice-carousel__next-btn img{
    height: 3vh;
    width: 3vh;
    transform: translateX(-2vw) translateY(8vh);
    opacity: .7;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    transition: 1s;
    border: 1px solid white;
    object-fit: contain;
    padding: 1vw 1vw;
  }

  .alice-carousel__prev-btn img{
    height: 3vh;
    width: 3vh;
    transform: translateX(2vw) translateY(8vh);
    opacity: .7;
    background-color: rgba(0, 0, 0, 0.2);
    padding: 1vw 1vw;
    border-radius: 50%;
    transition: 1s;
    object-fit: contain;
    border: 1px solid white;
  }

  .alice-carousel__prev-btn img:hover,
  .alice-carousel__next-btn img:hover{
    cursor: pointer;
    opacity: 1;
    transition: .2s;
  }

  .alice-carousel__prev-btn [data-area]::after,
  .alice-carousel__next-btn [data-area]::after {
    position: relative;
    content: attr(data-area);
    text-transform: capitalize;
  }
  
  .alice-carousel__next-btn {
    text-align: right;
  }
  
  .alice-carousel__prev-btn-item,
  .alice-carousel__next-btn-item {
    display: inline-block;
    cursor: pointer;
    padding: 5px;
    margin: 0;
    color: #ffffff;
  }
  .alice-carousel__prev-btn-item:hover,
  .alice-carousel__next-btn-item:hover {
    color: rgb(203, 203, 203);
  }
  .alice-carousel__prev-btn-item.__inactive,
  .alice-carousel__next-btn-item.__inactive {
    opacity: 0.4;
    pointer-events: none;
  }
  
  .alice-carousel__play-btn {
    position: absolute;
    top: 30px;
    left: 20px;
    display: inline-block;
  }
  .alice-carousel__play-btn:hover {
    cursor: pointer;
  }
  .alice-carousel__play-btn-wrapper {
    position: relative;
    width: 32px;
    height: 32px;
    padding: 10px;
    border-radius: 50%;
    background-color: #fff;
  }
  
  .alice-carousel__play-btn-item {
    position: absolute;
    width: 32px;
    height: 32px;
    cursor: pointer;
    border: 0;
    outline: none;
    background: transparent;
  }
  .alice-carousel__play-btn-item::before, .alice-carousel__play-btn-item::after {
    position: absolute;
    pointer-events: none;
    display: block;
    width: 0;
    height: 0;
    content: "";
    transition: all 0.4s linear;
    border-width: 8px 0 8px 15px;
    border-style: solid;
    border-color: transparent;
    border-left-color: #9f9f9f;
  }
  .alice-carousel__play-btn-item::before {
    left: 5px;
    height: 14px;
  }
  .alice-carousel__play-btn-item::after {
    top: 7px;
    left: 18px;
  }
  .alice-carousel__play-btn-item.__pause::before, .alice-carousel__play-btn-item.__pause::after {
    height: 30px;
    border-width: 0 0 0 10px;
  }
  .alice-carousel__play-btn-item.__pause::after {
    top: 0;
    left: 18px;
  }
  
  .alice-carousel__dots {
    margin: 30px 3px 5px;
    padding: 0;
    list-style: none;
    text-align: center;
  }
  .alice-carousel__dots > li {
    display: inline-block;
  }
  .alice-carousel__dots-item:not(.__custom) {
    width: 8px;
    height: 8px;
    cursor: pointer;
    border-radius: 50%;
    background-color: #e0e4fb;
  }
  .alice-carousel__dots-item:not(.__custom):not(:last-child) {
    margin-right: 20px;
  }
  .alice-carousel__dots-item:not(.__custom):hover, .alice-carousel__dots-item:not(.__custom).__active {
    background-color: #6e6e6e;
  }
  
  .alice-carousel__slide-info {
    position: absolute;
    top: 20px;
    right: 20px;
    display: inline-block;
    padding: 5px 10px;
    color: #465798;
    border-radius: 5px;
    background-color: rgba(224, 228, 251, 0.6);
  }
  .alice-carousel__slide-info-item {
    vertical-align: middle;
    line-height: 0;
  }

  @media (max-width: 1000px) {
    .product-title {
        margin: 0 15vw;
    }
}

@media (max-width: 500px) {
    .product-title {
        font-size: 8vw;
    }
}