.portfolio {
    background-color: #fffffff1;
}

.first-slider .slider-image{
    height: 50vh;
    width:100%; 
    object-fit: cover;
}

.first-slider {
    height: 50vh;
}

.first-slider .alice-carousel__prev-btn img,
.first-slider .alice-carousel__next-btn img{
    opacity: 0;
}

.first-slider .slider-image-div h3 {
    opacity: 0;
}

.portfolio-main {
    display: flex;
    flex-direction: column;
    margin-top: 50vh;
}

.portfolio-slider-outer{
    height: 52vh;    
    padding-left: 20vw;
    padding-right: 10vw;
    margin-bottom: 50vh;
}

.portfolio-slider-inner {
    transform: translateY(-40vh);
}

.portfolio-slider-outer:last-of-type {
    margin-bottom: 0;
}

.portfolio-slider-inner .alice-carousel__wrapper {
    border: 5px solid rgb(225, 225, 225);
    height: 70vh;
}

.portfolio-slider-inner h2{
    font-family: Louis George Cafe;
    color: rgb(154, 154, 154);
    font-size: 2.6rem;
    cursor: pointer;
}

.portfolio-slider-inner h2:hover{
    color: rgb(187, 187, 187);
}

.portfolio-title {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.portfolio-title a {
    color: rgb(154, 154, 154);
    text-decoration: none;
}

.portfolio-title a:hover {
    color: rgb(187, 187, 187);
}

.portfolio-title img{
    height: 35px;
    margin-right: 10px; 
}

.products-and-services {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Louis George Cafe;
}
.products-and-services .h2-div {
    width: 100%;
    z-index: 1;
    background: transparent;
    height: 0;
}

.products-and-services .h2-div .h2 {
    color: rgba(255, 255, 255, 0.7);
    font-size: 7vw;
    font-family: Chub Gothic;
    transform: translateY(-122%);
    margin-left: 2vw;
}

.services-outer {
    background: linear-gradient(to right, rgba(22, 38, 52, 0.5),rgba(200, 200, 200, 0.6),rgba(200, 200, 200, 0.6),rgba(22, 38, 52, 0.5));
    width: 100%;
}
.services-inner{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin: 8vw 5vw;
    
}

.service {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 3vh 0 1vh 0;
    /* background-color: lightgoldenrodyellow; */
}

.products-and-services .p-div {
    padding-left: 35px;
}

.p-div p {
    font-size: 1.5rem;
    margin-top: 1vh;
    color: rgb(103, 103, 103);
    margin-left: 18px;
    font-weight: 800;
}
.title {
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    color: rgb(54, 54, 54)
}

.title h2 {
    margin: 0 20px ;
}
.title:hover {
    color: white;
    text-shadow: -1px 2px 1px black;
    cursor: pointer;
}

.red-square {
    transition: .5s;
    background-color: red;
    box-shadow: -2px 2px rgba(255, 255, 255, 0.696);
    height: 30px;
    width: 30px;

}
.yellow-square {
    transition: .5s;
    background-color: yellow;
    box-shadow: -2px 2px rgba(255, 255, 255, 0.696);
    height: 30px;
    width: 30px;
}
.blue-square {
    transition: .5s;
    background-color: blue;
    box-shadow: -2px 2px rgba(255, 255, 255, 0.696);
    height: 30px;
    width: 30px;
}



@media (min-width: 1200px) and (max-height:800px){
.portfolio-slider-inner {
    transform: translateY(-45vh);
}
}

@media (max-width: 1300px) and (orientation: landscape) {
    .portfolio-slider-outer{
        padding-left: 10vw;
        padding-right: 5vw;
    }
}

@media (max-width: 1050px) {

    .portfolio-slider-inner h2 {
        font-size: 4vw;
    }
}


@media(max-width: 900px) and (orientation: portrait) {
    .portfolio-main {
        margin-top: 30vh;
    }
    .portfolio-slider-outer{
        padding-left: 10vw;
        padding-right: 5vw;
        margin-bottom: 30vh;
    }
    .portfolio-slider-outer{
        height: 30vh;
    }
    .portfolio-slider-outer .slider-image {
        height: 30vh;
    }
    .portfolio-slider-inner .alice-carousel__wrapper {
        border: 3px solid rgb(225, 225, 225);
        height: 30vh;
    }
    .portfolio-slider-inner h2{
        font-family: Louis George Cafe;
        color: rgb(154, 154, 154);
        /* font-size: 3.5vw; */
    }
    .portfolio-title {  
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .portfolio-slider-inner {
        transform: translateY(-15vh);
    }




    .first-slider .slider-image{
        height: 30vh;
    }
    
    .first-slider {
        height: 30vh;
    }
    
    .portfolio .alice-carousel__prev-btn img,
    .portfolio .alice-carousel__next-btn img{
      transform: translateY(27vh);
      height: 2vh;
      width: 2vh;
      padding: 2vw 2vw;
    }

    .products-and-services .h2-div .h2 {
        font-size: 10vw;
    }

}

@media(max-width: 950px) and (orientation: landscape) {
    .portfolio-main {
        margin-top: 100vh;
    }
    .portfolio-slider-outer{
        height: 80vh;
    }
    .portfolio-slider-outer .slider-image {
        height: 80vh;
    }
    .portfolio-slider-inner .alice-carousel__wrapper {
        border: 3px solid rgb(225, 225, 225);
        height: 80vh;
    }
    .portfolio-slider-inner h2{
        font-family: Louis George Cafe;
        color: rgb(154, 154, 154);
        font-size: 2rem;
    }

    .portfolio .alice-carousel__prev-btn,
    .portfolio .alice-carousel__next-btn {
        display: inline-block;
        box-sizing: border-box;
        width: 50%;
        padding: 10px 5px;
        transform: translateY(-55vh);
    }
    .portfolio .alice-carousel__prev-btn img,
    .portfolio .alice-carousel__next-btn img{
        width: 6vh;
        height: 6vh;
        padding: 2vw 2vw;
    }
    .portfolio-title {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .portfolio-title img{
        height: 35px;
        margin-right: 10px; 
    }
    .portfolio-slider-inner {
        transform: translateY(-40vh);
    }
    
    .products-and-services {
        height: 70vh;
    }

    .products-and-services .h2-div .h2 {
        font-size: 8vw;
    }

    .service {
        flex-direction: row;
        width: 100%;
        margin:1vh;
    }
    .service .title{
        width: 40%;
        padding: 0;
        font-size: 1rem;
    }
    .products-and-services .p-div {
        width: 60%;
        padding-left:0;
    }
    .p-div p{
        padding: 0; 
        font-size: 1.1rem;
        position: relative;
        top: 8px;
    }

}

@media(max-width: 700px) and (orientation: portrait) {
    .portfolio-slider-inner .alice-carousel__wrapper {
        border: 1px solid gray;
    }
    .portfolio-slider-outer{
        padding-left: 1vw;
        padding-right: 1vw;
    }
    .products-and-services .h2-div{
        text-align: center;

    }
    .products-and-services .h2-div .h2{
        font-size: 14vw;
        margin-left: 0
    }
    .service h2 {
        font-size: 1.8rem;
    }
    /* .portfolio-slider-inner {
        transform: translateY(-11vh);
    } */
    .portfolio .slider-image-div .image-title {
        margin-bottom: 0;
        font-size: 1rem;
    }
    .portfolio-title h2 {
        font-size: 5.8vw;
    }
    .portfolio-title img {
        margin-right: 5px;
    }
}

@media(max-width: 500px) {
    .portfolio-slider-outer {
        height: 30vh;
    }
    .service p {
        font-size: 1rem;
    }
    .first-slider .slider-image{
        height: 25vh;
    }
    
    .first-slider {
        height: 25vh;
    }
    .first-slider .alice-carousel__prev-btn img,
    .first-slider .alice-carousel__next-btn img{
      transform: translateY(27vh);
      width: 30px;
      height: 55px;
    }

    .products-and-services .h2-div{
        text-align: center;

    }
    .products-and-services .h2-div .h2{
        font-size: 14vw;
        margin-left: 0
    }
}

@media(max-width: 300px){
    .portfolio-title h2 {
        font-size: 1rem;
    }
}