.about{
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    z-index: 1;
    background-color: #e7e7e7f6;
    font-family: Louis George Cafe;
    font-size: large;
}

.about .alice-carousel__wrapper {
    height: 50vh;
}

.about-inner-div {
    display: flex; 
    justify-content: center;
    /* background-color: red; */
    width:100vw;
    margin: 20vh 0 10vh 0;
}

.company-story {
    /* background-color: blue; */
    width: 40%;
    text-align: justify;
    margin-right: 2vw;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.company-story h2:first-of-type {
    margin-top: 0;
}

.juan-story {
    /* background-color: green; */
    width: 30%;
}

.juan-story p {
    margin: 0;
    line-height: 1.2;
}

.about .image-div img {
    width: 16vw;
    height: 22vw;
    object-fit: cover;
    float: left;
    margin: 0 10px 10px 0;
}

.about .signature {
    font-family: Louis George Cafe;
    color: black;
    font-size: 1.5rem;
    font-weight: 800;
}

.about ul {
    padding-inline-start: 20px;
}

@media(max-width: 1600px) {
    .about .image-div img {
        width: 20vw;
        height: 28vw; 
    }
    .juan-story {
        width: 35%;
    }
}

@media(max-width: 1100px) {
    .about {
        font-size: larger;
    }
    .about .image-div img {
        width: 22vw;
        height: 30vw; 
    }
    .bio-div {
        text-align: left;
    }
}
@media(max-width: 850px) {
    .company-story {
        width: 46%;
    }
    .juan-story {
        width: 50%;

    }
    .about .image-div img {
        width: 30vw;
        height: 40vw;
    }
}

@media(max-width: 500px){
    .about-inner-div {
        flex-direction: column;
        justify-content: center;
    }
    .company-story {
        width:96%;
        margin: 0 2vw;
        text-align: left;
        justify-content: center;
    }
    .juan-story {
        width: 96%;
        margin: 10vh 2vw 0 2vw;
    }
    .about .image-div img {
        width: 50vw;
        height: 70vw;
    }
    .about h3 {
        font-size: 6vw;
    }
}


@media(max-width: 900px) and (orientation: landscape) {
    .about {
        padding-top: 10vh;
    }
}




