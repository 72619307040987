.navbar{
    height:10vh;
    width: 100vw;
    position:fixed;
    top:0%;
    font-size:1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    position:fixed;
    z-index: 99;
    background: linear-gradient(to right, rgba(22, 38, 52, 0.5),rgba(200, 200, 200, 0.6),rgba(200, 200, 200, 0.6),rgba(22, 38, 52, 0.5));
    font-family: Chub Gothic;
}

.navbar .nav-menu {
    display:flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width:100%;
}

.navbar .nav-item {
    transition: 1s;
    cursor:pointer;
    color: rgb(220, 220, 220);
    text-shadow: -1px 1px 1px black;
}

.navbar .nav-item.active {
    font-size: 1.8rem;
    font-weight: 900;
    transition: 1s;
    color: rgb(255, 255, 255);
}

.navbar .nav-item:hover {
    font-size: 1.8rem;
    transition: 1s;
    color: rgb(255, 255, 255);

}

@media(max-width: 900px){ 
    .navbar .nav-item {
        font-size: 2.7vw;
    }
    .navbar .nav-item.active {
        font-size: 4.2vw;
    }
}

@media(max-height: 800px) and (orientation: landscape) {
    .navbar{
        height:12vh;
    }
    
}
@media(max-height: 500px) and (orientation: landscape) {
    .navbar{
        height:15vh;
    }
}
