.how {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fffffff1;
    font-family: Louis George Cafe;
    border-top: 20vh solid transparent;
}

.how .h2-div{
    width: 100%;
    z-index: 1;
    background: transparent;
    height: 0;
}

.how h2 {
    color: rgba(0, 0, 0, 0.5);
    font-size: 7vw;
    font-family: Chub Gothic;
    transform: translateY(-122%);
    margin-left: 2vw;
    background-color: transparent;
    z-index: 99;
}

.steps-outer {
    background: linear-gradient(to right, rgba(22, 38, 52, 0.5),rgba(200, 200, 200, 0.6),rgba(200, 200, 200, 0.6),rgba(22, 38, 52, 0.5));
    width: 100%;

}

.steps {
    display: flex;
    justify-content: space-around;
    margin: 14vw 5vw 2vw 5vw;
}

.step {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 20%;
    text-align: center;
}

.step:hover img {
    transition: .5s;
    transform: scale(1.1);
}

.step img{
    cursor: pointer;
    width: 40%;
    transition: .5s;
    object-fit: contain;
}

.step h2{
    cursor: pointer;
    font-size: 2rem;
    transition: .5s;
}

.step p{
    cursor: pointer;
    font-size: 1.3rem;
    transition: 1s;
    font-size: 1rem;
    color: rgb(87, 87, 87);
}

@media(max-width: 900px) and (orientation: portrait) {
    .how h2 {
        font-size: 10vw;
    }
}
@media(max-width: 950px) and (orientation: landscape) {
    .how {
        border-top: 30vh solid transparent;
    }
    .how .h2 {
        font-size: 8vw;
    }
    .step p{
        font-size: .8rem;
    }
}
@media(max-width: 700px) and (orientation: portrait) {
    .step p{
        font-size: .8rem;
    }
    .how .h2-div{
        text-align: center;
    }
    .how .h2{
        font-size: 13vw;
        margin-left: 0
    }
    .steps {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        position: relative;
        top: -6%;
        margin: 16vw 5vw 0 5vw;
    }
    .step {
        width: 50%;
    }

}

@media(max-width: 500px) and (orientation: portrait) {
    .how .h2 {
        font-size: 12.5vw;
    }
}


