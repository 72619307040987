.menu {
    position: fixed;
    z-index: 99;
    width: 100vw;
    height: 7vh;
}

.menu-icon {
    position: absolute;
}

.menu-button-div {
    padding-left: 5px;
    padding-top: 5px;
    background: linear-gradient(to right, rgba(22, 38, 52, 0.5),rgba(200, 200, 200, 0.6),rgba(200, 200, 200, 0.6),rgba(22, 38, 52, 0.5));
    height: 5vh;
    width: 100vw;
}

.menu-button {
    width: 35px;
    height: 35px;
    object-fit: contain;
}

.menu .close-button {
    margin-left: 10px;
    margin-top: 10px;
    width: 25px;
    height: 25px;
}

.items {
    display:flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 15vh;
    padding: 6vw;
    background: linear-gradient(to right, rgba(22, 38, 52, 0.5),rgba(200, 200, 200, 0.6),rgba(200, 200, 200, 0.6),rgba(22, 38, 52, 0.5));
}


.items .item {
    transition: 1s;
    color: rgb(220, 220, 220);
    text-shadow: 1px 1px 1px black;
    font-size: 6vw;
}

.items .item.active {
    font-size: 9vw;
    transition: 1s;
    color: rgb(255, 255, 255);
}

@media(max-height: 800px){
    .menu-button {
        width: 5vh;
        height: 5vh;
    }
}