.home {
    height:100vh;
    width:100%;
    object-fit: cover;
}

.home .logo-slogan {
    position: fixed;
    z-index: -1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
}

.home .logo{
    object-position:top;
    object-fit: contain;
    margin-top: 15vh;
    width: 50vw;
    height: 100%;
}
.home .logo img {
    width: 100%;
}

.home .slogan h1{
    color: #c9c9c9f1;
    display: flex;
    justify-content: space-between;
    font-size: 4.2vw;
    width: 50vw;
}

@media(max-width: 1600px) {
    .home .logo{
        margin-top: 16vh;
        width: 45vw;
    }
    .home .slogan h1{
        width: 45vw;
        font-size: 3.8vw;
    }

}

@media(max-width: 1100px) and (orientation: landscape) {
    .home .logo {
        width: 55vw;
        margin-top: 20vh;
    }
    .home .slogan h1{
        width: 55vw;
        font-size: 4.5vw;
    }
}

@media(max-width: 1000px) and (orientation: portrait) {
    .home .logo {
        margin-top: 20vh;
        width: 80vw;
    }
    .home .slogan h1{
        font-size: 6.2vw;
        width: 80vw;
    }
}

@media(max-width: 900px) and (orientation: landscape) {
    .home .logo {
        width: 36vw;
        margin-top: 22vh;
    }
    .home .slogan h1{
        width: 36vw;
        font-size: 3vw;
    }
}

@media(max-width: 500px) and (orientation: portrait) {
    .home .logo {
        margin-top: 30vh;
    }
    .home .slogan h1{
        font-size: 6.5vw;
    }
}



