.admin-image-outer {
    display: flex;
    flex-direction: column;
}

.admin-image-outer .admin-image-list {
    display: flex;
    flex-wrap: wrap;
}

.admin-image-outer .admin-image-form input{
    width:45%;
}

.admin-image-outer .admin-image-list img{
    width: 200px;
    height: 200px;
    object-fit: cover;
    object-position: top;
    margin: 5px;
}

.admin-image-outer .image-div {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.admin-image-outer ::placeholder {
    color: gray;
    font-size: 1rem;
}

