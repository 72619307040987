.contact{
    width:100vw;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    background-color: rgba(255, 255, 255, 0.928);
    color:rgb(71, 71, 71);
}

.banner-contact-div{
    width:100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
    line-height: 1.5rem;
    padding: 20vh 0 15vh 0;
}

.contact-info {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    font-family: Louis George Cafe;;
    font-size: larger;
    width: 40vw;
}

.form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
}

.input {
    width: 35vw;
    font-family: Louis George Cafe;
    border-radius: .1rem;
    border: none;
    padding:10px 5px;
    background-color: #cacaca;
    border: 1px solid rgb(146, 146, 146);
    margin: 2vh 0;
}

.input:hover {
    background-color: #d9d9d9;
}

.input.message{
    height:15vh;
}

::placeholder {
    color: white;
    font-weight: 800;
    font-size: 1.1rem;
}

.submit-button-div{
    width:100%;
    display: flex;
    justify-content: flex-end;
}

.submit-button-div input {
    padding: .3rem 1.5rem;
    border-radius: .2rem;
    transition: .3s;
    background-color: rgba(66, 66, 66, 0.853);
    color: white;
    border: 1px solid rgba(66, 66, 66, 0.853);
}

.submit-button-div input:hover {
    background-color: #c9c9c9f1;
    cursor: pointer;
    color:rgb(60, 60, 60);
    transition: .3s;
    border: 1px solid rgb(60, 60, 60);
}

.footer-outer {
    background: linear-gradient(to right, rgba(22, 38, 52, 0.5),rgba(200, 200, 200, 0.6),rgba(200, 200, 200, 0.6),rgba(22, 38, 52, 0.5));
    margin-top: 10vh;
    width: 100%;
}

.footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 6vw;
}

.fl-tx {
    position:absolute;
    left:5vw;
    display: flex;
}

.fl-tx h2 {
    color: white;
    font-size: 2rem;
    margin: 0 1vw;
}

.footer img{
    height: 30px;
    margin: 20px;
    transition:1s;
    cursor: pointer;
}

.socials-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    border: 1px solid white;
    background-color: rgba(103, 103, 103, 0.3);
    transition: 1s;
    margin: 2vw;
}

.socials-logo:hover {
    background-color: rgb(103, 103, 103);
    transform: scale(1.2);
}

.subject {
    width: 35vw;
    border-radius: .1rem;
    padding:10px 5px;
    background-color: #cacaca;
    border: 1px solid rgb(146, 146, 146);
    margin: 2vh 0;
}

.subject:hover {
    background-color: #d9d9d9;
    cursor: pointer;
    color:rgb(60, 60, 60);
    border: 1px solid rgb(60, 60, 60);
}

.subject label{
    font-family: Louis George Cafe;
    font-size: 1.1rem;
    font-weight: 800;
    color: white;
    margin-right: 1vw;
}

.subject select {
    border: none;
}

@media(max-width: 900px) {
    .input {
        width: 40vw;
    }
    .subject {
        width: 40vw;
    }
    .contact-info {
        width: 55vw;
    }
    .fl-tx {
        left:2vw;
    }
    .fl-tx h2{
        font-size: 1.5rem;
    }
    .footer {
        margin-right: 0;
    }
}

@media(max-width: 600px) {
    .fl-tx {
        flex-wrap: wrap;
        width: 30vw;
    }
}

@media(max-width: 500px) {
    .banner-contact-div {
        flex-direction: column;
        align-items: center;
        font-size: larger;
    }
    .contact-info {
        width: 90vw;
    }
    .form {
        align-items: center;
        margin-top: 10vh;
    }
    .input {
        width: 90vw;
    }
    .subject {
        width: 90vw;
    }
    .fl-tx {
        left:1vw;
    }
    .fl-tx h2{
        font-size: 5vw;
    }
}