.gallery-slider-outer {
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(198, 198, 198, 0.81);
    overflow: scroll;
    position: fixed;
}
.gallery-slider {
    /* background-color: lightcoral; */
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.gallery-slider .image-title-div{
    height: 90%;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    /* background-color: lightgray; */
}

.gallery-slider .image-div {
    /* height: 100%; */
    /* width: 70%; */
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: lightskyblue; */
}

.gallery-slider .title-div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.gallery-slider .slider-image {
    max-width: 70vw;
    max-height: 70vh;
    object-fit: contain;
    border: 5px solid white;
}


.gallery-slider .title-div h3 {
    color: white;
    text-shadow: -1px 1px black;
    font-family: Louis George Cafe;
    font-size: 1.5rem;
    transition: .5s ;
    margin:0;
}

.gallery-slider .button-div {
    height: 10%;
    width: 100%;
    position: absolute;
    top: 1vw;
    right: 1vw;
    /* background-color: gray; */
}

.gallery-slider .button-div img{
    width: 30px;
    height: 30px;
    padding: 5px;
    border-radius: 50%;
    position: absolute;
    top:10px;
    right: 10px;
    background-color: rgba(255, 255, 255, 0.712);
    border: 2px solid rgb(0, 0, 0);
    opacity: .5;
    cursor: pointer;
}

@media(max-width: 1200px) {
    .gallery-slider .title-div h3 {
        font-size: 1.3rem;
    }
}

@media (max-width: 1000px) {
    .gallery-slider .title-div h3 {
        font-size: 1.1rem;
    }
}

@media(max-width: 750px) {
    .gallery-slider .title-div h3 {
        font-size: 1rem;
    }
}

@media(max-width: 700px) {
    .gallery-slider .image-title-div{
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .gallery-slider .title-div h3 {
        margin-top: 5px;
        font-size: 1.3rem;
    }
}

@media (max-width: 500px) {
    .gallery-slider .slider-image {
        max-width: 90vw;
        left: 0;
    }
}